import React from 'react';
import styled from 'styled-components';

const Greeting = () => {
  const userHour = new Date().getHours();

  return (
    <IntroParagraph>
      I just like making websites. I’m a Staff Product Designer at{' '}
      <a
        href="https://datadoghq.com/"
        rel="noopener noreferrer"
        target="_blank"
      >
        Datadog
      </a>
      {''}. Before that I worked at{' '}
      <a href="https://glitch.com" rel="noopener noreferrer" target="_blank">
        Glitch
      </a>
      {', '}
      <a href="https://genius.com" rel="noopener noreferrer" target="_blank">
        Genius
      </a>
      , and{' '}
      <a
        href="https://thoughtbot.com"
        rel="noopener noreferrer"
        target="_blank"
      >
        thoughtbot
      </a>
      . I live in New York.
    </IntroParagraph>
  );
};

export default Greeting;

const IntroParagraph = styled.p`
  display: block;
  margin: 0;
  font-size: 30px;
  line-height: 1.4;

  @media screen and (max-width: 480px) {
    font-size: 18px;
    line-height: 1.4;
  }
`;
